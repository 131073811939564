<template>
  <section class="shared-kit-label">
    <div class="shared-kit-label__text">{{ text }}</div>
  </section>
</template>

<script setup lang="ts">
import type { ISharedKitLabelProps } from './SharedKitLabel.types';

const props = defineProps<ISharedKitLabelProps>();

const styleVariables = computed(() => ({
  labelBackground: GlobalUtils.CSS.getBackgroundColor(props.background),
  labelColor: GlobalUtils.CSS.getBackgroundColor(props.color),
}));
</script>

<style scoped lang="scss">
.shared-kit-label {
  --label-background: v-bind('styleVariables.labelBackground');
  --label-color: v-bind('styleVariables.labelColor');
}
</style>

<style scoped lang="scss" src="./SharedKitLabel.scss" />
